import { IGroup, IGroupTerminal } from "../types/Group";

class GroupModel implements IGroup {
  exhibitionName?: string;
  icon?: string;
  hasActivePlaylist: boolean;
  hasActiveNetwork: boolean;
  hasActiveCamera: boolean;
  isDefault: boolean;
  label: string;
  locationToken?: string;
  macAddressListToIgnoreOnMonitor: string[];
  name: string;
  terminalsList: IGroupTerminal[];
  token: string;

  constructor(data: any) {
    let terminalsList: IGroupTerminal[] = [];

    if (data.terminals) {
      data.terminals.forEach((terminal: any) => {
        terminalsList.push({
          name: terminal.terminal_name,
          token: terminal.terminal_token,
          color: terminal.color,
          category: terminal.category || "standard",
          isActive: terminal.is_active === "True",
          isLocked: terminal.is_locked === "True",
        });
      });
    }

    this.label = data.group_name || data.name;
    this.name = data.group_name || data.name;
    this.exhibitionName = data.location_group_name || this.name;
    this.token = data.group_token || data.token;
    this.hasActivePlaylist = data.playlist === "True";
    this.hasActiveNetwork = data.network === "True";
    this.hasActiveCamera = data.camera === "True";
    this.isDefault = data.default === "True";
    this.locationToken = data.location_token;
    this.icon = data.group_icon;
    this.terminalsList = terminalsList || [];
    this.macAddressListToIgnoreOnMonitor = data.monitor_macaddress || [];
  }
}

export default GroupModel;
