import { when } from "mobx";
import { inject, observer } from "mobx-react";
import { Component, Fragment } from "react";

import { SimpleCard } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import SearchBar from "../../components/SearchBar/SearchBar";

import LoadingView from "../../components/LoadingView/LoadingView";
import Modal from "../../components/Modal/Modal";
import ModalLocationDetails from "../../components/Modal/ModalLocationDetails";

import InfoLabel from "../../components/Label/InfoLabel";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import Paginate from "../../components/Paginate/Paginate";

import LocationForm from "../locationsPage/LocationForm";
import AddLocationButton from "./AddLocationButton";
import LocationItemContainer from "./LocationItemContainer";

import Plural from "../../components/I18n/Plural";
import Translate from "../../components/I18n/Translate";

import LocationModel from "../../Model/LocationModel";
import AlfredStore from "../../store/AlfredStore/AlfredStore";
import LocationStore from "../../store/LocationStore/LocationStore";
import RoutingStore from "../../store/RoutingStore/RoutingStore";

import { EAppRoutes } from "../../routes/AppRoutes";

interface IListLocationsContainerProps {}

enum ModalType {
  add = "add",
  show = "show",
  edit = "edit",
  delete = "delete",
}

interface IListLocationsContainerState {
  activeModalType?: ModalType | null;
  selectedLocationToken?: string | null;
  selectedLocation?: LocationModel | null;
  isOpenModal?: boolean;
}

interface InjectedProps extends IListLocationsContainerProps {
  alfredStore: AlfredStore;
  locationStore: LocationStore;
  routingStore: RoutingStore;
}

@inject("alfredStore", "locationStore", "routingStore")
@observer
class ListLocationsContainer extends Component<
  IListLocationsContainerProps,
  IListLocationsContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  state = {
    activeModalType: null,
    selectedLocationToken: null,
    selectedLocation: null,
    isOpenModal: false,
  };

  componentDidMount() {
    const { alfredStore, locationStore, routingStore } = this.injected;
    locationStore.getLocationsList();

    setTimeout(() => {
      alfredStore.addActions({
        id: "locationsActions",
        label: "Localidade",
        options: [
          {
            id: "add",
            title: "Adicionar",
            subtitle: "Crie uma nova localidade",
            icon: "plus",
            callback: this.didWantToAddNewLocation,
          },
        ],
      });
    }, 1000);

    const [token, modalType] = routingStore.location.pathname
      .replace(`${EAppRoutes.LOCATIONS}/`, "")
      .replace(EAppRoutes.LOCATIONS, "")
      .split("/");

    if (token && modalType) {
      when(
        () => !locationStore.isFetching,
        () => this.didSelectLocation(token, ModalType[modalType])
      );
    } else if (token === "add") {
      this.setState({
        activeModalType: ModalType.add,
        isOpenModal: true,
      });
    }
  }

  componentDidUpdate(
    prevProps: IListLocationsContainerProps,
    prevState: IListLocationsContainerState
  ) {
    if (
      (prevState.selectedLocationToken === this.state.selectedLocationToken &&
        prevState.activeModalType === this.state.activeModalType) ||
      this.injected.routingStore.location.pathname.includes(
        this.state.selectedLocationToken
      )
    ) {
      return;
    }
    const { selectedLocationToken, activeModalType } = this.state;

    let url = EAppRoutes.LOCATIONS + "/";
    if (selectedLocationToken) {
      url += `${selectedLocationToken}/${activeModalType}`;
    } else if (activeModalType) {
      url += activeModalType;
    }

    this.injected.routingStore.push(url);
  }

  componentWillUnmount() {
    this.injected.alfredStore.removeActions("locationsActions");
  }

  didWantToAddNewLocation = () => {
    this.setState({
      activeModalType: ModalType.add,
      isOpenModal: true,
    });
  };

  didSelectLocation = (
    selectedLocationToken: string,
    activeModalType = ModalType.show
  ) => {
    const selectedLocation = this.injected.locationStore.getWithToken(
      selectedLocationToken
    );
    if (selectedLocation) {
      this.setState({
        selectedLocationToken,
        selectedLocation,
        activeModalType,
        isOpenModal: true,
      });
    } else {
      this.injected.routingStore.push(EAppRoutes.LOCATIONS);
    }
  };

  didWantToEditLocation = () => {
    this.closeModalAndSetNewState({
      activeModalType: ModalType.edit,
      isOpenModal: true,
    });
  };

  didWantToDeleteLocation = () => {
    this.closeModalAndSetNewState({
      activeModalType: ModalType.delete,
      isOpenModal: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      activeModalType: null,
      selectedLocationToken: null,
      selectedLocation: null,
      isOpenModal: false,
    });
  };

  didDeleteLocation = () => {
    this.injected.locationStore.deleteLocation(
      this.state.selectedLocationToken
    );
    this.didCancelDeleteAction();
  };

  closeModalAndSetNewState = (newState: IListLocationsContainerState) => {
    this.setState(
      {
        isOpenModal: false,
      },
      () => setTimeout(() => this.setState(newState), 200)
    );
  };

  didCloseModal = () => {
    this.closeModalAndSetNewState({
      activeModalType: null,
      selectedLocation: null,
    });
  };

  render() {
    if (this.injected.locationStore.isFetching) {
      return <LoadingView />;
    }

    const {
      activeModalType,
      selectedLocationToken,
      selectedLocation,
      isOpenModal,
    } = this.state;
    const { locationStore } = this.injected;
    const { filteredLocationsList: locations, locationsList } = locationStore;

    return (
      <Fragment>
        {selectedLocation && activeModalType === ModalType.delete && (
          <ConfirmDeleteModal
            didDelete={this.didDeleteLocation}
            didCancel={this.didCancelDeleteAction}
            deleteLabel={
              <Translate id="LocationsPage@confirmDeleteButton">
                Sim, apagar localidade!
              </Translate>
            }
            message={
              <Translate
                id="LocationsPage@deleteQuestion"
                // @ts-ignore
                variables={{ locationName: selectedLocation.name }}
              >
                Você realmente quer apagar <strong>$locationName</strong>?
              </Translate>
            }
          />
        )}
        {[ModalType.add, ModalType.edit].includes(activeModalType) && (
          <Modal
            isOpen={isOpenModal}
            didCloseModal={this.didCloseModal}
            modalTitle={
              activeModalType === ModalType.add ? (
                <Translate id="LocationsPage@addModalTitle">
                  Adicionar localidade
                </Translate>
              ) : (
                <Translate id="LocationsPage@editModalTitle">
                  Editar localidade
                </Translate>
              )
            }
          >
            {activeModalType && (
              <LocationForm
                // @ts-ignore
                location={selectedLocation}
                didCancel={this.didCloseModal}
                didSave={this.didCloseModal}
                didWantToDelete={this.didWantToDeleteLocation}
              />
            )}
          </Modal>
        )}
        {selectedLocationToken && activeModalType === ModalType.show && (
          <ModalLocationDetails
            location={selectedLocation}
            isOpen={isOpenModal}
            didWantToEdit={this.didWantToEditLocation}
            didWantToDelete={this.didWantToDeleteLocation}
            didCloseModal={this.didCloseModal}            
          />
        )}
        <GridContainer>
          {locationsList.length > 5 && (
            <GridItem xs={12}>
              <SimpleCard cardStyle={{ marginTop: 0 }}>
                <SearchBar
                  value={locationStore.searchString}
                  onChange={locationStore.setSearchString}
                />
              </SimpleCard>
            </GridItem>
          )}
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <AddLocationButton onClick={this.didWantToAddNewLocation} />
              </GridItem>
              <Paginate itemsList={locations} itemsPerPage={11}>
                {({ slicedList }) =>
                  slicedList.map((location: LocationModel) => (
                    <GridItem key={location.token} xs={12} sm={4} md={3} lg={3}>
                      <LocationItemContainer
                        location={location}
                        didWantToOpenLocationModal={this.didSelectLocation}
                      />
                    </GridItem>
                  ))
                }
              </Paginate>
            </GridContainer>
          </GridItem>
          {locationsList.length > 5 && (
            <GridItem xs={12} style={{ textAlign: "center", marginTop: 30 }}>
              <InfoLabel hideIcon>
                <Plural
                  // @ts-ignore
                  id="LocationsPage@locationQtyLabel"
                  value={locations.length}
                  zero="Nenhuma localidade encontrada"
                  one="# localidade"
                  other="# localidades"
                />
              </InfoLabel>
            </GridItem>
          )}
        </GridContainer>
      </Fragment>
    );
  }
}

export default ListLocationsContainer;
